'use strict';

angular.module('enervexSalesappApp').controller('ControlCtrl', function($scope, Control, $stateParams, Flash, $state, _, Fan, Util) {
	$scope.errors = {};
	$scope.itemCodeDisabled = true
	$scope.codeWarning = Util.setCodeWarning()
	$scope.disableItemCode = function(){
		if ($scope.itemCodeDisabled === true) {
			$scope.itemCodeDisabled = false
		} else {
			$scope.itemCodeDisabled = true
		}
	}
	if ($stateParams.id == "new") {
		$scope.control = {
			applicationSupport:[]
		}
		fetchDependents()
		$scope.itemCodeDisabled = false
	} else {
		Control.get({
			id: $stateParams.id
		}).$promise.then(function(control) {
			$scope.control = control;
			fetchDependents()
		})
	}
	function fetchDependents() {
		Fan.query({limit:200}).$promise.then(function(fans){
			$scope.fans = _.sortBy(fans, function(f){
				return f.code;
			});
			_.each($scope.control.applicationSupport, function(applicationSupport){
				addFans(applicationSupport)
			})
		})
	}
	function addFans(applicationSupport){
		applicationSupport.fans = _.map($scope.fans, function(_f){
			var result = _.clone(_f)
			var existing = _.find(applicationSupport.excludeIfFan, function(excludeFan){
				return excludeFan == result.code
			})
			if (existing){
				result.selected = true
			} else {
				result.selected = false
			}
			return result
		})
	}
	$scope.addAplicationSupport = function() {
		var newSupport = {
			includeSupplyNotNone: true,
			includeNonConstantVol:true,
			supportsMultiStory:true,
			maxNumberAppliances: 300,
			excludeIfFan: [],
			supportDamper: true,
			bothSupplyAndExhaust: true
		}
		addFans(newSupport)
		$scope.control.applicationSupport.push(newSupport);
		$scope.ctx.formChanged = true;
	}
	$scope.computeAses = [
		"BWH",
		"DRY",
		"COM"
	]
	$scope.ctx = {
		formChanged: false
	}

	$scope.delete = function(applicationSupport){
		$scope.ctx.formChanged = true;
		$scope.control.applicationSupport = _.filter($scope.control.applicationSupport, function(a){
			return a != applicationSupport
		})
	}
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.control);
			payload = _.omit(payload, "__v");
			_.each(payload.applicationSupport, function(applicationSupport){
				applicationSupport.excludeIfFan = _.pluck(_.where(applicationSupport.fans, {selected:true}), "code")
			})
			if (!payload._id) {
				Control.save(payload).$promise.then(function(result) {
					
					$scope.success = true;
					$scope.control = result;
					Flash.create('success', '<strong>Success!</strong> Successfully created control.', 0, {}, true);
					$state.go("control", {
						id: result._id
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				Control.update({
					id: payload._id
				}, payload).$promise.then(function(control) {
					
					$scope.success = true;
					$scope.control = control;
					_.each($scope.control.applicationSupport, function(applicationSupport){
						addFans(applicationSupport)
					})
					Flash.create('success', '<strong>Success!</strong> Successfully updated control.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});
